// extracted by mini-css-extract-plugin
export var analytics_image = "footer-module--analytics_image--ad3db";
export var footer_bottom = "footer-module--footer_bottom--07b08";
export var footer_link = "footer-module--footer_link--1fed8";
export var footer_top = "footer-module--footer_top--fede0";
export var mailto = "footer-module--mailto--a92b2";
export var social__link__aws = "footer-module--social__link__aws--3a032";
export var social__link__github = "footer-module--social__link__github--d7aea";
export var social__link__kununu = "footer-module--social__link__kununu--d20b4";
export var social__link__linkedin = "footer-module--social__link__linkedin--0fefd";
export var social__link__xing = "footer-module--social__link__xing--fb909";
export var social_links = "footer-module--social_links--e5fb4";