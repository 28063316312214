module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/build/src/components/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"html":{"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"url":"https://valiton-blog.valiton-intern.burda.com/graphql","type":{"MediaItem":{"localFile":{"maxFileSizeBytes":1000000000,"excludeByMimeTypes":[],"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"schema":{"typePrefix":"Wp","queryDepth":15,"circularQueryLimit":5,"timeout":30000,"perPage":100,"requestConcurrency":15,"previewRequestConcurrency":5},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"www.valiton.com","short_name":"www.valiton.com","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"minimal-ui","icon":"./src/images/favicon.png","icons":[{"src":"icon/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"icon/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"icon/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"icon/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"icon/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"icon/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"icon/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"icon/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b02cea79b719cca237a5ef916e69df95"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
