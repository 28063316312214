import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import Header from '../components/header'
import '../../node_modules/bootstrap/scss/bootstrap-grid.scss'
import '../css/layout.scss';
import Footer from '../components/footer'
import {graphql, useStaticQuery} from "gatsby"


class Layout extends Component {
        render() {
            const ogImageUrl = `${this.props.siteTitle.siteMetadata.siteUrl}${this.props.logo.gatsbyImageData.images.fallback.src}`;
            return (
                    <div>
                        <Helmet title={this.props.siteTitle.siteMetadata.title}>
                            <meta property="og:image" content={ogImageUrl} />
                            <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"></meta>
                        </Helmet>

                        <Header/>
                        <main>{this.props.children}</main>
                        <Footer/>
                    </div>
            )
        }
}

export default ({ children }) => {
    const data = useStaticQuery(graphql`
            query layout {
                siteTitle: site {
                    siteMetadata {
                        title,
                        siteUrl
                    }
                }
                logo: file(name: {eq: "favicon"}, extension: {eq: "png"}) {
                    childImageSharp {
                        gatsbyImageData(width: 1200, height: 630)
                    }
                }
            }
        `)
    return <Layout siteTitle={data.siteTitle} logo={data.logo.childImageSharp}>{children}</Layout>
}
