import React from 'react'
import { Link } from 'gatsby'
import * as styles from '../css/footer.module.scss'
import GoogleAnalytics from '../images/google_analytics.png'
import DynamicYield from '../images/partner_dynamicyield_stacked.png'
import Tealium from '../images/partner_tealium.png'
import AWS from '../images/aws-consulting-partner.png'
import StiboDX from '../images/partner_stibodx_stacked.png'


const Footer = ({siteTitle}) => (
    <footer>
        <div className={styles.footer_top}>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-8">
                        <div className={styles.analytics_image}>
                            <img className={styles.analytics_image} src={AWS} alt="AWS"/>
                            <img className={styles.analytics_image} src={GoogleAnalytics} alt="Google Analytics"/>
                            <img className={styles.analytics_image} src={DynamicYield} alt="Dynamic Yield"/>
                            <img className={styles.analytics_image} src={Tealium} alt="Tealium"/>
                            <img className={styles.analytics_image} src={StiboDX} alt="Stibo DX"/>
                        </div>

                        <p>Valiton zeigt zertifizierte Kompetenz im Bereich Cloud Consulting und Webanalytics.</p>
                    </div>

                    <div className="col-xs-12 col-md-4">
                        <h3>Kontakt</h3>

                        <p>Valiton GmbH<br/>
                            Arabellastraße 23<br/>
                            D-81925 München
                        </p>
                        <p>
                            Viktoria Selzer<br/>
                            Tel. 0781 84 3120
                        </p>
                        <p>
                            <a href="mailto:kontakt@valiton.com" className={styles.mailto}>kontakt@valiton.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div className={styles.footer_bottom}>
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-8 order-2 order-md-1">
                        <nav>
                            <ul>
                                <li>
                                    <Link to="/nutzungsbestimmungen"
                                          className={styles.footer_link}
                                    >
                                        Nutzungsbestimmungen
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/datenschutz" className={styles.footer_link}>Datenschutz</Link>
                                </li>
                                <li>
                                    <Link to="/impressum" className={styles.footer_link}>Impressum</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div className="col-xs-12 col-md-4 order-1 order-md-2">
                        <a href="https://www.xing.com/companies/valitongmbh"
                           target="_blank"
                           rel="noopener noreferrer"
                           className={styles.social__link__xing}
                        >
                            Xing
                        </a>
                        <a href="https://www.kununu.com/de/valiton1"
                           target="_blank"
                           rel="noopener noreferrer"
                           className={styles.social__link__kununu}
                        >
                            Kununu
                        </a>
                        <a href="https://www.linkedin.com/company/valiton"
                           target="_blank"
                           rel="noopener noreferrer"
                           className={styles.social__link__linkedin}>
                            LinkedIn
                        </a>
                        <a href="https://github.com/valiton"
                           target="_blank"
                           rel="noopener noreferrer"
                           className={styles.social__link__github}
                        >
                            Github
                        </a>
                        <a href="https://partners.amazonaws.com/partners/0010L00001u6RGaQAM/Valiton%20GmbH,%20ein%20BurdaSolutions%20Unternehmen"
                           target="_blank"
                           rel="noopener noreferrer"
                           className={styles.social__link__aws}
                        >
                            AWS Partner Page
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer
