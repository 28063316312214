/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {

    if (location.hash) {
        return false;
    }

    return true;
};