import React, {Component} from 'react'
import { Link } from 'gatsby'
import logo from '../images/ValitonLogo.svg'
import * as styles from '../css/header.module.scss'

class Header extends Component {

    handleLinkClick = () => {
        this.checkbox.checked = false;
    };

    render() {
        return (
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-md-3">
                            <Link to={"/"}>
                                <img className={styles.logo} src={logo} alt="Valiton Logo" />
                            </Link>
                        </div>
                        <div className="col-sm-8 col-md-9">
                            <nav role="navigation">
                                <div className={styles.menuToggle}>
                                    <input type="checkbox" ref={(checkbox) => this.checkbox = checkbox} />
                                    <span/>
                                    <span/>
                                    <span/>
                                    <ul className={styles.main_menu}>
                                        <li><Link onClick={this.handleLinkClick} activeClassName={styles.active} to={"/service"}>Service</Link></li>
                                        <li><Link onClick={this.handleLinkClick} activeClassName={styles.active} to={"/ueber-uns"}>Über uns</Link></li>
                                        <li><Link onClick={this.handleLinkClick} activeClassName={styles.active} to={"/blog"}>Blog</Link></li>
                                        <li><Link onClick={this.handleLinkClick} activeClassName={styles.active} to={"/karriere"}>Karriere</Link></li>
                                        <li><Link onClick={this.handleLinkClick} activeClassName={styles.active} to={"/technology-radar"}>Tech-Radar</Link></li>
                                        <li><Link onClick={this.handleLinkClick} activeClassName={styles.active} to={"/kontakt"}>Kontakt</Link></li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header



